<script setup lang="ts">
import {
  arrow,
  autoUpdate,
  flip,
  type FloatingElement,
  offset as offsetMiddleware,
  type OffsetOptions,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue'
import { defu } from 'defu'

const props = withDefaults(
  defineProps<{
    content?: string
    offset?: OffsetOptions
    placement?: Placement
    paddingX?: number
    paddingY?: number
    closeDelay?: number
    open?: boolean
    interactive?: boolean
    classes?: {
      wrapper?: string | string[]
      reference?: string | string[]
      floating?: string | string[]
      arrow?: string | string[]
    }
  }>(),
  {
    content: '',
    offset: 12,
    placement: 'top',
    paddingX: 0,
    paddingY: 0,
    closeDelay: 0,
    classes: () => ({}),
  },
)

const classes = computed(() =>
  defu(props.classes, {
    wrapper: 'inline-block w-fit',
    reference: 'inline-block w-fit',
    floating:
      'w-max rounded bg-black p-1 px-2 font-sans text-xs font-normal leading-tight text-white dark:bg-white dark:text-black',
    arrow: 'size-2 rotate-45 bg-black dark:bg-white',
  }),
)

const open = ref(props.open)
const reference = shallowRef<HTMLElement>()
const floatingArrow = shallowRef<HTMLElement>()
const floating = ref<FloatingElement>()
const middleware = ref([
  offsetMiddleware(props.offset),
  flip({
    fallbackAxisSideDirection: 'start',
    crossAxis: false,
    padding: props.paddingY,
  }),
  shift({
    padding: props.paddingX,
  }),
  arrow({
    element: floatingArrow,
  }),
])
const {
  floatingStyles,
  middlewareData,
  placement: placementComputed,
} = useFloating(reference, floating, {
  open,
  placement: props.placement,
  middleware,
  whileElementsMounted: autoUpdate,
})

const hasFocus = ref(false)
const arrowStyles = computed(() => {
  const styles: {
    left?: string
    top?: string
    right?: string
    bottom?: string
  } = {}

  if (middlewareData.value.arrow?.x != null) {
    styles.left = `${middlewareData.value.arrow.x}px`
  }
  if (middlewareData.value.arrow?.y != null) {
    styles.top = `${middlewareData.value.arrow.y}px`
  }

  switch (placementComputed.value) {
    case 'right': {
      styles.left = '-4px'
      break
    }
    case 'left': {
      styles.left = ''
      styles.right = '-4px'
      break
    }
    case 'left-start': {
      styles.top = '4px'
      styles.left = ''
      styles.right = '-4px'
      break
    }
    case 'left-end': {
      styles.top = ''
      styles.bottom = '4px'
      styles.left = ''
      styles.right = '-4px'
      break
    }
    case 'bottom': {
      styles.top = '4px'
      break
    }
    case 'bottom-end': {
      styles.top = '4px'
      break
    }
    case 'bottom-start': {
      styles.left = '8px'
      styles.top = '4px'
      break
    }
  }

  return styles
})

let hideTimeout: ReturnType<typeof setTimeout> | undefined

function show() {
  if (hideTimeout) {
    clearTimeout(hideTimeout)
    hideTimeout = undefined
  }
  open.value = true
}
function hide(force = false) {
  if (hideTimeout) {
    clearTimeout(hideTimeout)
    hideTimeout = undefined
  }
  if (!force && hasFocus.value)
    return

  if (force)
    doHide()
  else hideTimeout = setTimeout(doHide, props.closeDelay)
}
function doHide() {
  hideTimeout = undefined
  open.value = false
}
</script>

<template>
  <span
    :class="classes.wrapper"
    @mouseenter="() => show()"
    @mouseleave="() => hide()"
    @focus="
      () => {
        if (!props.interactive) return
        show();
      }
    "
    @focusin="
      () => {
        if (!props.interactive) return
        hasFocus = true;
        show();
      }
    "
    @blur="
      () => {
        if (!props.interactive) return
        hide();
      }
    "
    @focusout="
      () => {
        if (!props.interactive) return
        hasFocus = false;
        hide();
      }
    "
  >
    <span ref="reference" :class="classes.reference">
      <slot v-bind="{ open, hide, show }" />
    </span>
    <div
      v-if="open && ('content' in $slots || props.content)"
      ref="floating"
      :class="classes.floating"
      class="z-50"
      :style="floatingStyles"
    >
      <slot name="content" v-bind="{ open, hide, show }">
        <span>{{ props.content }}</span>
      </slot>

      <div
        ref="floatingArrow"
        class="absolute"
        :class="[
          classes.arrow,
          placementComputed.startsWith('bottom') ? '-translate-y-full' : '',
        ]"
        :style="arrowStyles"
      />
    </div>
  </span>
</template>
